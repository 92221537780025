<template>
  <div class="video-container">
    <div class="video-overlay">
      <b-container fluid>
        <b-row>
          <b-col class="text-center">
            <b-img :src="gruun_logo_url" fluid /><br>
            <b-button variant="primary" class="mt-4">Bekijk ons aanbod</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <video id="player" :src="video_path" muted autoplay loop></video>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      video_path: require("@/assets/Gruun_homepage_video.webm"),
      gruun_logo_url: require("@/assets/logo_Gruun.png"),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.outer-container {
  width: 100%;
  height: 100%;
  text-align: center;
}
.video-container {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-overlay {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
